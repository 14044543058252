<template>
  <div>
    <!-- :data="[{affiliate_name:affiliateBillings[0].affiliate_name, value: parseMoney(affiliateBillings.map(item => item.value).reduce((acc, item) => parseFloat(acc) + parseFloat(item)))}]" -->
    <q-dialog v-model="dialog" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <div class="text-h6">Qual foi a data do pagamento?</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <!-- <q-input outlined dense type="date" v-model="paymentDate" autofocus @keyup.enter="dialog = false" /> -->
          <date-input
            outlined
            label="Data de pagamento"
            :v-model="paymentDate"
            @update="(value) => (paymentDate = value)"
            :rules="[isRequired]"
            clearable
          />
        </q-card-section>

        <q-card-actions align="right">
          <q-btn outline no-caps color="primary" label="Cancelar" v-close-popup />
          <q-btn no-caps unelevated color="green" label="Confirmar" @click="onBtnPayment" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-table
      flat
      bordered
      row-key="id"
      class="q-mt-md"
      :data="affiliateBillings"
      :columns="columns"
      hide-bottom
      :pagination="{ rowsPerPage: 1 }"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.label }}
          </q-th>
          <q-th auto-width />
        </q-tr>
      </template>

    <template v-slot:body="props">
        <q-tr :props="props">
          <q-td v-for="col in props.cols" :key="col.name" :props="props">
            {{ col.value }}
  </q-td>
  <q-td auto-width>
    <q-btn
      size="sm"
      color="accent"
      round
      dense
      @click="props.expand = !props.expand"
      :icon="props.expand ? 'add' : 'remove'"
    />
  </q-td>
</q-tr>
<q-tr v-show="!props.expand" :props="props">
    <q-td colspan="100%">
      <q-table
        :data="affiliateBillings"
        flat
        row-key="commissions"
        selection="multiple"
        :selected.sync="selected"
        :columns="columnsChild"
        hide-bottom
        :pagination.sync="pagination"
      />
      <div class="row items-center" style="width: 100%">
        <span class="q-mr-xs">Registros por página:</span>
        <q-select
        v-model="pagination.rowsPerPage"
        :options="[10, 20, 50, 100]"
        dense
        @input="onRowsPerPageChange"
        style="max-width: 60px; padding-left: 8px; padding-right: 8px"
        />
        <span>Total: {{ reqPagination.total }}</span>
        <q-space />
        <q-pagination
          unelevated
          v-model="pagination.page"
          color="primary"
          :max="Math.ceil(reqPagination.total / pagination.rowsPerPage)"
          :max-pages="6"
          boundary-numbers
          direction-links
        />

      </div>
    </q-td>
  </q-tr>
</template>
    </q-table>
    <div class="q-ma-md row justify-center">
      <q-btn
        color="positive"
        label="Dar baixa"
        @click="onBtnDialog"
        no-caps
        unelevated
        class="q-px-md"
        :disable="this.selected.length > 0 ? false : true"
      />
    </div>
  </div>
</template>

<script>
import FinancialMixin from '../../mixins/FinancialMixin';
import DateInput from '../../widgets/inputsGlobal/DateInput.vue';

export default {
  name: 'ListAffiliateBilling',
  components: { DateInput },
  mixins: [FinancialMixin],
  props: { affiliateId: Number },

  data() {
    return {
      dialog: false,
      paymentDate: '',
      selected: [],
      pagination: {
        rowsPerPage: 10,
      },
      columns: [
        {
          name: 'affiliate_name',
          label: 'Afiliado',
          align: 'left',
          field: 'affiliate_name'
        },
        {
          name: 'value',
          label: 'Valor da fatura',
          align: 'center',
          field: 'value',
          format: () =>
            this.parseMoney(
              this.affiliateBillings.map((item) => item.value).reduce((acc, item) => parseFloat(acc) + parseFloat(item))
            )
        }
      ],
      columnsChild: [
        {
          name: 'channel_name',
          required: true,
          label: 'Nome do canal',
          align: 'left',
          field: 'channel_name',
          format: (val, row) => val + ' (id ' + row.channel_id + ')',
          sortable: true
        },
        {
          name: 'campaign_name',
          required: true,
          label: 'Nome da campanha',
          align: 'left',
          sortable: true,
          field: 'campaign_name',
          format: (val, row) =>
            val + ' (Período: ' + this.$moment(`${row.month}'1'${row.year}`).format('LL').replace('1 de', '') + ')'
        },
        {
          name: 'value',
          required: true,
          label: 'Valor da fatura',
          align: 'left',
          field: 'value',
          sortable: true,
          format: (val) => this.parseMoney(val)
        }
      ]
    };
  },

  created() {
    const savedRowsPerPage = localStorage.getItem('rowsPerPage');
    const rowsPerPage = savedRowsPerPage ? parseInt(savedRowsPerPage, 10) : 10;

    this.pagination.rowsPerPage = rowsPerPage;
  },

  methods: {
    async onBtnPayment() {
      this.dialog = false;
      let commissions = [];
      for (let i = 0; i < this.selected.length; i++) {
        let commission = {
          channel_id: this.selected[i].channel_id,
          campaign_id: this.selected[i].campaign_id,
          state_id: this.selected[i].state_id,
          month: this.selected[i].month,
          year: this.selected[i].year
        };
        commissions[i] = commission;
      }

      let date = this.paymentDate.split('/').reverse().join('-');

      this.loading = true;
      try {
        const { status } = await this.$http.put('/v2/redirect', {
          app: 'finance',
          path: `/api/commission-affiliate/checkout/${this.affiliateId}`,
          params: {
            data: {
              commissions: {
                comission: commissions,
                payment_date: date
              }
            }
          }
        });
        if (status == 200) {
          this.successNotify('Baixa de valores realizada com sucesso!');
          this.getAffiliateBillingsPaginated(this.affiliateId);
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.dialog = false;
        this.loading = false;
        this.selected = [];
      }
    },

    onBtnDialog() {
      this.dialog = true;
    },

    onRowsPerPageChange(value) {
      localStorage.setItem('rowsPerPage', value);
      this.pagination.rowsPerPage = value;
    },
  },
};
</script>
